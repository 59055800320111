.property {
  position: relative;
}
.property[data-editable="false"] {
  font-style: italic;
  cursor: default;
}
.property[data-visible="false"] {
  color: red;
  display: none;
}
.property-choice {
  padding-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: white;
  font-size: 14px;
  color: #616161;
}
.property-choice.selected {
  font-size: 16px;
  background-color: #F0F0F0;
  color: #000;
}

.property-choice > img {
  max-width: 50px;
  margin-right: 10px;
  border-radius: 5px;
}

.property-title {
  background-color: #7D7875;
  font-size: 18px;
  color: white;
  width: 100%;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.property-choice {
  padding: 20px;
  border-bottom: 1px solid #d2d2d2;
}

.property-choice:last-child {
  border: none;
}