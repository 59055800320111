#progress {
  background-color: rgba(255, 255, 255, 0.3);
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #151515;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: calc(40% - 40px);
  left: calc(50% - 40px);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}