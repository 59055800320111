html, body {
  margin: 0;
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}
* {
  box-sizing: border-box;
}
#app-container {
  height: 100%;
  width: 100%;
}

.header {
  width: 100%;
  height: 180px;
}

.footer {
  width: 100%;
  height: 250px;
}

.invisible {
  width: 0px !important;
}

.header, .footer {
  display: none;
}

.main {
  display: grid;
  grid-template-areas: "A B";
  grid-template-columns: 1fr auto;
  height: 688px; /* 100% - height of header - height of footer - margin*/
}

#pcon-viewer {
  grid-area: A;
  width: 973px;
}

#property-editor {
  grid-area: B;
  overflow: auto;
  border-left: 1px solid #E0E0E0 !important;
  width: 384px;
  display: flex;
  flex-direction: column;
  height: 688px;
}

#dnt-controls {
  order:2;
  border-top: 1px solid #E0E0E0;
  padding: 20px;
  width: 100%;
  bottom: 0;
  background-color: white;
  background-color: #eee;
}

#dnt-controls div {
  padding-bottom: 20px;
  color: #616161;
}

#dnt-controls div span {
  color: black;
  font-size: 20px;
  margin-right: 20px;
}

#dnt-controls button, #dnt-controls a {
  text-decoration: none;
  background-color: black;
  color: white !important;
  text-transform: uppercase !important;
  width: 100%;
  display: block;
  font-size: 14px;
  padding: 16px !important;
  text-align: center;
  border: none;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.property-choice {
  color: #616161;
}
.property-choice.selected {
  color: #000;
}

.property-class {
  overflow: auto;
}

.pcon-viewer-control-tr {
  position: absolute;
  top: 20px;
  right: 20px;
}

.pcon-top-titel {
  font-weight: 600;
  font-size: 18px;
  position: absolute;
  top: 20px;
  left: 20px;
  line-height: 26px;
}

.pcon-viewer-control-tr .pcon-button {
  margin: 1px;
}

.icon-copy {
  cursor: pointer;
}

.pcon-button {
  width: 48px;
  height: 48px;
  background-color: #eee;
  border: none;
  cursor: pointer;
}

.camera {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.pdf {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.dnt-dropdown-export {
  background-color: #eee;
  height: 48px;
  position: absolute;
  bottom: 20px;
  left: 80px;
  padding: 0 10px;
  border-right: 8px solid #eee;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
}

@media screen and (max-width: 820px) {
  .main {
    grid-template-areas: 
              "A"
              "B";
    height: 944px;
  }
  
  #pcon-viewer {
    height: 370px;
    width: 100%;
  }

  #property-editor {
    width: 100%;
    height: 100%;
    border-left: none !important;
  }

  .invisible {
    height: 0 !important;
  }

  .fullscreen {
    display: none;
  }
}

@media screen and (max-width: 400px){
  .dnt-dropdown-export {
    display: none;
  }
}