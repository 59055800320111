#oap {
  position: fixed;
  background-color: white;
  border: 1px solid lightgrey;
  overflow: auto;
  max-height: 500px;
}

/* property edtior */
.oap-property-editor .title {
  border-bottom: 1px solid lightgrey;
  padding: 5px;
  text-align: center;
}
.oap-property-editor .property {
  padding: 5px;
  padding-right: 15px;
}
.oap-property-editor .property[data-choice-list="true"][data-editable="true"]::after {
  content: '\025B8';
  top: unset;
}

/* action edtior */
.oap-action-editor {
  max-width: 220px;
}
.oap-action-editor.tile {
  max-width: 820px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.oap-action-editor.list {
  display: block;
}
.oap-action {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: auto;
  border: 1px solid lightgrey;
}
.oap-action.small {
  width: 100px;
}
.oap-action.medium {
  width: 200px;
}
.oap-action.large {
  width: 400px;
}
.oap-action-icon {
  max-width: 100%;
  max-height: 100%;
}
.oap-action-text {
  
}
.oap-media {
  overflow: initial !important;
}
.oap-media .close {
  position: absolute;
  top: -25px;
  right: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 1px solid black;
  background-color: white;
  text-align: center;
}